'use client'

import { useAuth } from '@/contexts/auth-context';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useParams } from 'next/navigation';

export default function Home() {
  const { user, loading } = useAuth();
  const router = useRouter();
  const params = useParams();
  const locale = params.locale as string;

  useEffect(() => {
    if (!loading) {
      if (user) {
        router.push(`/${locale}/application-page`);
      } else {
        router.push(`/${locale}/signin`);
      }
    }
  }, [user, loading, router, locale]);

  // Show loading state while authentication status is being determined
  if (loading) {
    return <div>Loading...</div>;
  }

  return null;
} 